import React, { useState } from 'react'

import { Link, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Seo from '../components/seo'
import Pagination from '../components/Pagination'
import Layout from '../components/layout'
import ReactMarkdown from 'react-markdown'

const FenceGalleryPage = props => {
  const [pageIndex, setPageIndex] = useState(1)
  const [perPage] = useState(1200)
  const offSet = (pageIndex - 1) * perPage
  const fenceGalleryData = props?.data?.fenceGalleryData?.edges || []
  const pageCount = Math.ceil(fenceGalleryData.length / perPage)
  const sliceData = fenceGalleryData.slice(offSet, offSet + perPage)
  console.log('fenceGalleryData', fenceGalleryData)
  return (
    <Layout>
      <div className='max-w-6xl mx-auto bg-white drop-shadow-xl border-t-7 md:px-8 px-4 pb-4 mt-8'>
        <ReactMarkdown
          children={props?.data?.markdownRemark?.frontmatter?.content}
          components={{
            p: ({ children }) => {
              return (
                <p className='font-light text-sm text-gray-600 mb-6 max-w-full leading-6'>
                  {children}
                </p>
              )
            },
            h1: ({ children }) => {
              return (
                <h1 className='text-3xl text-gray-600 leading-7 uppercase font-light title pb-5 mt-7 text-center mb-8'>
                  {children}
                </h1>
              )
            },
            h2: ({ children }) => {
              return (
                <h2 className='text-3xl text-gray-600 leading-7 uppercase font-light title pb-5 mt-7 text-center mb-8'>
                  {children}
                </h2>
              )
            },
            h3: ({ children }) => {
              return (
                <h3 className='md:text-3xl text-xl text-gray-600 uppercase mt-5 font-medium title pb-5 mt-7 text-center mb-10 '>
                  {children}
                </h3>
              )
            },
            h4: ({ children }) => {
              return (
                <h4 className='text-2xl font-bold font3 mb-4'>{children}</h4>
              )
            },
            ul: ({ children }) => {
              return (
                <ul className='md:mb-16 mb-6 pl-2 list-disc'>{children}</ul>
              )
            },
            li: ({ children }) => {
              return <li className='text-sm font-normal mb-3'>{children}</li>
            }
          }}
        />
        <div className='categories'>
          <ul className='grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-8 mb-5 p-0'>
            {sliceData &&
              sliceData.map((item, index) => {
                return (
                  <li className='mb-0' key={index}>
                    <div className='item_box p-5 text-center'>
                      {item?.node?.frontmatter?.slug && (
                        <Link
                          to={`/${item?.node?.frontmatter?.slug}`}
                          className='mb-5 w-full inline-block'
                          aria-label={item?.node?.frontmatter?.title}
                        >
                          {' '}
                          <GatsbyImage
                            image={getImage(
                              item?.node?.frontmatter?.featuredimage
                            )}
                            className='w-full border border-gray-300'
                          />
                        </Link>
                      )}
                      {item?.node?.frontmatter?.title && (
                        <h3 className='uppercase text-base font-normal text-center lg:h-24 text-gray-700 mb-6'>
                          {' '}
                          {item?.node?.frontmatter?.title}
                        </h3>
                      )}
                      {item?.node?.frontmatter?.slug && (
                        <Link
                          to={`/${item?.node?.frontmatter?.slug}`}
                          aria-label={item?.node?.frontmatter?.title}
                          className='font1 bg-white border-b border-gray-400 text-sm text-gray-700 inline-block py-2 px-4 uppercase rounded-md font-normal hover:text-cyan-400'
                        >
                          Read More
                        </Link>
                      )}
                    </div>
                  </li>
                )
              })}
          </ul>
          {/* <ul className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-8 mb-5 p-0">
                        {sliceData && sliceData.map((item, index) => {
                            return (
                                <li className="mb-0">
                                    <div className="item_box p-5 text-center">
                                    <Link to={`/${item.node.url}`} className='mb-5 w-full inline-block'> <GatsbyImage image={getImage(item.node.img)} className="w-full border border-gray-300" /></Link>
                                        <h3 className="uppercase text-base font-normal text-center lg:h-24 text-gray-700 mb-6"> {item.node.name}</h3>
                                        <Link to={`/${item.node.url}`} className="font1 bg-white border-b border-gray-400 text-sm text-gray-700 inline-block py-2 px-4 uppercase rounded-md font-normal hover:text-cyan-400">Read More</Link>
                                    </div>
                                </li>
                            )
                        })}
                    </ul> */}
        </div>
        <Pagination
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          pageCount={pageCount}
          currentPage={pageIndex}
        />
      </div>
    </Layout>
  )
}

export const Head = props => (
  <Seo
    title={props?.data?.markdownRemark?.frontmatter?.meta?.seoTitle}
    description={props?.data?.markdownRemark?.frontmatter?.meta?.seoDescription}
    path={props.location.pathname}
  />
)

export const data = graphql`
  query {
    markdownRemark(frontmatter: { slug: { eq: "fence-gallery" } }) {
      frontmatter {
        content
        meta {
          seoTitle
          seoDescription
        }
      }
    }
    fenceGalleryData: allMarkdownRemark(
      filter: {frontmatter: {templateKey: {eq: "fence-gallery"}, status: {eq: "Published"}}}
      sort: {frontmatter: {orderfield: ASC}}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            slug
            featuredimage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 211, height: 160)
              }
            }
          }
        }
      }
    }
    
  }
`

// galleryData: allGalleryJson {
//       edges {
//         node {
//       name
//       code
//       url
//       img
//     }
//   }
// }

export default FenceGalleryPage
